import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { Device, DeviceHeartbeat, DeviceWithVenue, Order, OrderMeta } from '../types';

import { getQueryResource } from './query';
import { getMetaQueryResource } from './meta';
import axiosInstance from './axiosInstance';
import { rangeStartEnd } from '../filtering/filter';

export async function getDevices(
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getQueryResource<DeviceWithVenue>(
    '/devices',
    query,
    page,
    limit,
    undefined,
    now,
    undefined,
    sortingField,
    sortDirection,
  );
}

export async function getDevicesMeta() {
  return await getMetaQueryResource('/devices/meta');
}

export async function getDevice(deviceId: string) {
  const response = await axiosInstance.get(`/devices/${deviceId}`);

  return response.data as DeviceWithVenue;
}

export async function getDeviceOrders(
  uuid: string,
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getQueryResource<Order>(
    `/devices/${uuid}/orders`,
    query,
    page,
    limit,
    range,
    now,
    'rent_time',
    sortingField,
    sortDirection,
  );
}

export async function getDeviceOrdersMeta(uuid: string) {
  return await getMetaQueryResource(`/devices/${uuid}/orders/meta`);
}

export async function getDeviceHeartbeats(
  deviceId: string,
  range?: DateRangePickerProps.Value | null,
) {
  const params = new URLSearchParams();

  if (range) {
    const { start, end } = rangeStartEnd(range);
    // correct format and correct UTC time
    params.append('start', start.toISOString());
    params.append('end', end.toISOString());
  }
  console.log(params.toString());
  const response = await axiosInstance.get(`/devices/${deviceId}/heartbeats?${params.toString()}`);

  return response.data as DeviceHeartbeat[];
}
