import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
  Link,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { UserListResponse, ROLE_INFO } from '../../common/types';
import { getTextFilterCounterText } from '../../common/i18n-strings';
import { getHeaderCounterText } from '../../common/full-header';

import { filterUsers } from './filter';
import { getUsers } from '../../common/api/users';
import { useQuery } from '@tanstack/react-query';

export function UsersTable() {
  const pageSize = 10;
  const resourceName = 'User';
  const [selectedItems, setSelectedItems] = useState<UserListResponse[]>([]);
  const navigate = useNavigate();

  const { data, isPending, isFetching, error, refetch } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
  });

  const fetchUsers = async () => {
    setSelectedItems([]);
    await refetch();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(data ?? [], {
    propertyFiltering: {
      filteringProperties: [
        {
          key: 'email',
          operators: [':', '!:'],
          propertyLabel: 'Email',
          groupValuesLabel: 'Email values',
          defaultOperator: ':',
        },
        {
          key: 'roles',
          operators: [':', '!:'],
          propertyLabel: 'Roles',
          groupValuesLabel: 'Role values',
          defaultOperator: ':',
        },
      ],
      filteringFunction: filterUsers,
      empty: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error.message}
                </Box>
              </div>
              <Button onClick={fetchUsers}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      ),
      noMatch: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No matches</b>
              <Box color="inherit" variant="p">
                We can&apos;t find a match.
              </Box>
            </div>
            <Button onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}>
              Clear filter
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize },
    selection: {},
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'created',
          sortingComparator(a, b) {
            const x = new Date(a.created_at);
            const y = new Date(b.created_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...propertyFilterProps}
      {...paginationProps}
      ariaLabels={{
        selectionGroupLabel: 'Items selection',
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
        itemSelectionLabel: ({ selectedItems }, item) => {
          const isItemSelected = selectedItems.filter((i) => i.email === item.email).length;

          return `${item.email} is ${isItemSelected ? '' : 'not'} selected`;
        },
      }}
      columnDefinitions={[
        {
          id: 'email',
          header: 'Email',
          cell: (item) => (
            <Link
              href={`/manage/users/${item.uuid}`}
              variant="secondary"
              onFollow={(e) => {
                e.preventDefault();
                navigate(`/manage/users/${item.uuid}`);
              }}
            >
              {item.email}
            </Link>
          ),
          sortingField: 'email',
          sortingComparator(a, b) {
            const x = a.email.toLowerCase();
            const y = b.email.toLowerCase();

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        // {
        //   id: 'escaped',
        //   header: 'Escaped',
        //   cell: (e) => e.escaped,
        //   sortingField: 'escaped',
        //   sortingComparator(a, b) {
        //     const x = a.escaped;
        //     const y = b.escaped;

        //     return x < y ? -1 : x > y ? 1 : 0;
        //   },
        // },
        // {
        //   id: 'steps',
        //   header: 'Steps',
        //   cell: (e) => e.steps,
        //   sortingField: 'steps',
        //   sortingComparator(a, b) {
        //     const x = a.steps;
        //     const y = b.steps;

        //     return x < y ? -1 : x > y ? 1 : 0;
        //   },
        // },
        // {
        //   id: 'hints',
        //   header: 'Hints',
        //   cell: (e) => e.hints,
        //   sortingField: 'hints',
        //   sortingComparator(a, b) {
        //     const x = a.hints;
        //     const y = b.hints;

        //     return x < y ? -1 : x > y ? 1 : 0;
        //   },
        // },
        {
          id: 'created',
          header: 'Created',
          // TODO format date & make it sortable
          cell: (e) => new Date(e.created_at + 'Z').toLocaleString(),
          sortingField: 'created',
          sortingComparator(a, b) {
            const x = new Date(a.created_at);
            const y = new Date(b.created_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'roles',
          header: 'Roles',
          cell: (e) =>
            ROLE_INFO.filter((role) => e.roles.includes(role.name) && role.name !== 'basic')
              .map((role) => role.display)
              .join(', '),
          sortingField: 'roles',
          sortingComparator(a, b) {
            const x = ROLE_INFO.filter(
              (role) => a.roles.includes(role.name) && role.name !== 'basic',
            )
              .map((role) => role.display)
              .join(', ');
            const y = ROLE_INFO.filter(
              (role) => b.roles.includes(role.name) && role.name !== 'basic',
            )
              .map((role) => role.display)
              .join(', ');

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ]}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount || 0)}
          disabled={isPending}
          filteringOptions={[
            {
              propertyKey: 'roles',
              value: 'Internal',
            },
            {
              propertyKey: 'roles',
              value: 'Venue',
            },
            {
              propertyKey: 'roles',
              value: 'Admin',
            },
          ]}
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={isPending} iconName="refresh" onClick={fetchUsers} />
            </SpaceBetween>
          }
          counter={getHeaderCounterText(data ?? [], collectionProps.selectedItems)}
        >
          Users
        </Header>
      }
      items={items}
      loading={isPending}
      loadingText="Loading users"
      pagination={<Pagination {...paginationProps} disabled={isPending} />}
      selectedItems={selectedItems}
      selectionType="multi"
      trackBy="email"
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
    />
  );
}
