import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Link,
  Pagination,
  PropertyFilter,
} from '@cloudscape-design/components';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { AuthPermission } from '../../../common/types';
import { getHeaderCounterText } from '../../../common/full-header';
import { getTextFilterCounterText } from '../../../common/i18n-strings';
import { getRolePermissions } from '../../../common/api/auth';
import { useQuery } from '@tanstack/react-query';

function filterOperator(a: boolean, b: boolean, operator: 'and' | 'or') {
  return operator === 'and' ? a && b : a || b;
}

function filterPermissions(perm: AuthPermission, query: PropertyFilterQuery) {
  let matches = query.operation === 'and';

  for (const token of query.tokens) {
    if (token.propertyKey === 'name') {
      if (token.operator === ':') {
        matches = filterOperator(
          matches,
          perm.name.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      } else if (token.operator === '!:') {
        matches = filterOperator(
          matches,
          !perm.name.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      }
    }
  }

  return matches;
}

export type AuthRolePermissionResponse = {
  id: number;
  name: string;
};

export type RolePermissionsTableProps = {
  role: string;
};

export function RolePermissionsTable(props: RolePermissionsTableProps) {
  const pageSize = 10;
  const resourceName = 'Permission';
  const navigate = useNavigate();

  const rolePermissionsReq = useQuery({
    queryKey: ['auth', 'role', props.role, 'permissions'],
    queryFn: () => getRolePermissions(props.role),
    staleTime: 360_000,
  });

  const error = rolePermissionsReq.error;
  const loading = rolePermissionsReq.isPending;

  const fetchPermissions = async () => {
    await rolePermissionsReq.refetch();
  };

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(
    (rolePermissionsReq.data ?? []).map((v, idx) => {
      return {
        id: idx,
        name: v.name,
      };
    }),
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: 'name',
            operators: [':', '!:'],
            propertyLabel: 'Name',
            groupValuesLabel: 'Name values',
            defaultOperator: ':',
          },
        ],
        filteringFunction: filterPermissions,
        defaultQuery: {
          operation: 'and',
          tokens: [],
        },
        empty: (
          <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
            {error ? (
              <SpaceBetween size="xxs">
                <div>
                  <b>Error retrieving {resourceName.toLowerCase()}s</b>
                  <Box color="inherit" variant="p">
                    {error.message}
                  </Box>
                </div>
                <Button onClick={fetchPermissions}>Retry</Button>
              </SpaceBetween>
            ) : (
              <SpaceBetween size="xxs">
                <div>
                  <b>No {resourceName.toLowerCase()}s</b>
                  <Box color="inherit" variant="p">
                    No {resourceName.toLowerCase()}s found.
                  </Box>
                </div>
              </SpaceBetween>
            )}
          </Box>
        ),
        noMatch: (
          <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
            <SpaceBetween size="xxs">
              <div>
                <b>No matches</b>
                <Box color="inherit" variant="p">
                  We can&apos;t find a match.
                </Box>
              </div>
              <Button
                onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}
              >
                Clear filter
              </Button>
            </SpaceBetween>
          </Box>
        ),
      },
      pagination: { pageSize },
      selection: {},
    },
  );

  return (
    <Table
      {...collectionProps}
      {...propertyFilterProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Name',
          cell: (item) => (
            <Link
              href={`/manage/auth/permissions/${item.name}`}
              variant="secondary"
              onFollow={(e) => {
                e.preventDefault();
                navigate(`/manage/auth/permissions/${item.name}`);
              }}
            >
              {item.name}
            </Link>
          ),
        },
      ]}
      empty={
        <Box color="inherit" textAlign="center">
          <b>No permissions</b>
          <Box color="inherit" padding={{ bottom: 's' }} variant="p">
            No permissions to display.
          </Box>
        </Box>
      }
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount || 0)}
          disabled={loading}
          filteringOptions={[]}
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={loading} iconName="refresh" onClick={fetchPermissions} />
            </SpaceBetween>
          }
          counter={getHeaderCounterText(
            rolePermissionsReq.data ?? [],
            collectionProps.selectedItems,
          )}
        >
          Permissions
        </Header>
      }
      items={items}
      loading={loading}
      loadingText="Loading permissions"
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      trackBy="name"
    />
  );
}
