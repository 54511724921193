import {
  Container,
  SpaceBetween,
  Button,
  Alert,
  Header,
  ColumnLayout,
  Checkbox,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { RoleStatus, RoleUpdateRequest, ROLE_INFO, UserListResponse } from '../../../common/types';
import { getUser, updateUserRoles } from '../../../common/api/users';
import { useMutation, useQuery } from '@tanstack/react-query';

export type UserDetailsProps = {
  user: UserListResponse;
  setUser: (user: UserListResponse) => void;
};

export function UserRolesInfo(props: UserDetailsProps) {
  const { user } = props;

  const [roles, setRoles] = useState<RoleStatus[]>(
    ROLE_INFO.map((role) => {
      return {
        ...role,
        enabled: user.roles.includes(role.name),
      };
    }),
  );

  const updateRolesReq = useMutation({
    mutationFn: (data: RoleUpdateRequest) => updateUserRoles(user.uuid, data),
    onSuccess: () => {
      getUserQuery.refetch();
    },
  });

  const getUserQuery = useQuery({
    queryKey: ['user', user.uuid],
    queryFn: () => getUser(user.uuid),
    staleTime: 0,
  });

  useEffect(() => {
    if (getUserQuery.data) {
      props.setUser(getUserQuery.data);
    }
  }, [getUserQuery.data]);

  const error = updateRolesReq.error || getUserQuery.error;
  const loading = updateRolesReq.isPending || getUserQuery.isPending;

  const updateRoles = async () => {
    if (loading) return;
    updateRolesReq.mutate({
      roles: roles.filter((role) => role.enabled).map((role) => role.name),
    });
  };

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            actions={
              <Button loading={loading} variant="normal" onClick={updateRoles}>
                Save
              </Button>
            }
            variant="h2"
          >
            Roles
          </Header>
        }
      >
        <ColumnLayout columns={1} variant="text-grid">
          {roles.map((role) => (
            <Checkbox
              key={`role-${role.name}-checkbox`}
              checked={role.enabled}
              disabled={loading || role.name === 'basic'}
              onChange={({ detail }) => {
                const newRoles = [...roles];

                for (const newRole of newRoles) {
                  if (newRole.name === role.name) {
                    newRole.enabled = detail.checked;
                  }
                }
                setRoles(newRoles);
              }}
            >
              {role.display}
            </Checkbox>
          ))}
        </ColumnLayout>
      </Container>
      {error ? (
        <Alert header="Role Save Error" statusIconAriaLabel="Error" type="error">
          {error.message}
        </Alert>
      ) : null}
    </SpaceBetween>
  );
}
