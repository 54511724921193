import axios from 'axios';
import { RefObject, useContext, useEffect, useRef } from 'react';
import jwt from 'jwt-decode';

import UserContext from '../user';
import { TokenRefreshResponse, User, UserTokenInfo } from '../types';
import { ApiRequest, useRefreshApi } from '../api';

const useLive = false;

export const liveApiUrl = 'https://api.dashboard.needzappy.com';

export const apiUrl =
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !useLive
    ? 'http://localhost:80'
    : liveApiUrl;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function getAccessTokenSilently(
  user: UserTokenInfo,
  token: string | null,
  setUser: (user: UserTokenInfo | null) => void,
  setToken: (token: string | null) => void,
  setRefreshToken: (refreshToken: string | null) => void,
  refreshApi: () => Promise<ApiRequest<TokenRefreshResponse>>,
  loading: boolean,
  refreshPromiseRef: RefObject<Promise<string | null> | null>,
): Promise<string | null> {
  // Check if the token has expired.
  if (user && user.exp < Date.now() / 1000) {
    // If no refresh is in progress, start one.
    if (!refreshPromiseRef.current) {
      refreshPromiseRef.current = refreshApi()
        .then((result) => {
          if (result.error) {
            setUser(null);
            setToken(null);
            setRefreshToken(null);
            console.error(result.error);

            return null;
          } else if (result.data) {
            const newToken = result.data.token;
            setToken(newToken);
            setUser(newToken ? (jwt(newToken) as UserTokenInfo) : null);

            return newToken;
          }

          return null;
        })
        .finally(() => {
          // Reset the promise once the refresh call is complete.
          refreshPromiseRef.current = null;
        });
    }

    // Wait for the refresh promise (whether it’s just been created or was already in-flight).
    return await refreshPromiseRef.current;
  }

  // Token is still valid.
  return token;
}

export const useAxiosInterceptors = () => {
  const { user, setUser, token, setToken, setRefreshToken, loading } = useContext(UserContext);
  const [refreshApi] = useRefreshApi();

  // Create a React ref to hold the in-flight refresh promise.
  const refreshPromiseRef = useRef<Promise<string | null> | null>(null);

  useEffect(() => {
    // Only register the interceptor if a user is logged in and a token exists.
    if (user && token) {
      const interceptor = axiosInstance.interceptors.request.use(async (config) => {
        try {
          const liveToken = await getAccessTokenSilently(
            user,
            token,
            setUser,
            setToken,
            setRefreshToken,
            refreshApi,
            loading,
            refreshPromiseRef,
          );

          if (liveToken) {
            config.headers.Authorization = `Bearer ${liveToken}`;
          }
        } catch (error) {
          console.error('Error getting access token:', error);
        }

        return config;
      });

      // Clean up the interceptor when dependencies change or the component unmounts.
      return () => {
        axiosInstance.interceptors.request.eject(interceptor);
      };
    }
  }, [user, token, refreshApi, loading, setUser, setToken, setRefreshToken]);
};

export default axiosInstance;
