import { UserInvite, UserInviteResponse } from '../types';
import axiosInstance from './axiosInstance';

export async function inviteUsers(info: UserInvite[]) {
  await axiosInstance.post('/invite', info);
}

export async function getInvites() {
  const response = await axiosInstance.get('/invite');

  return response.data as UserInviteResponse[];
}

export async function deleteEmailInvites(emails: string[]) {
  await axiosInstance.delete('/invite', { data: emails });
}
