import { Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router';

import { Venue } from '../common/types';

export type VenueLinkProps = {
  venue: Venue;
  maxLength?: number;
};

export function VenueLink(props: VenueLinkProps) {
  const { venue } = props;
  const navigate = useNavigate();

  const name = venue.name.trim();

  const trimmedName = props.maxLength
    ? name.slice(0, props.maxLength) + (name.length > props.maxLength ? '...' : '')
    : name;

  return (
    <Link
      href={`/venues/${venue.uuid}`}
      variant="secondary"
      onFollow={(e) => {
        e.preventDefault();
        navigate(`/venues/${venue.uuid}`);
      }}
    >
      {trimmedName}
    </Link>
  );
}
