import { useContext } from 'react';
import { Navigate } from 'react-router';

import { UserTokenInfo } from './types';
import UserContext, { hasPermission, hasPermissionPrefix } from './user';

export type RequireAuthParams = {
  children: React.ReactNode;
};

export type RequirePermissionsParams = {
  children: React.ReactNode;
  permissions: string[];
};

export function RequireAuth(params: RequireAuthParams) {
  const { user } = useContext(UserContext);
  // encode window.location.pathname  and window.location.search
  const redirect = encodeURIComponent(window.location.pathname + window.location.search);

  return user ? <>{params.children}</> : <Navigate to={`/login?redirect=${redirect}`} replace />;
}

export function RequireNoAuth(params: RequireAuthParams) {
  const { user } = useContext(UserContext);

  return user ? <Navigate to="/" replace /> : <>{params.children}</>;
}

export function getPermissionsPrefix(prefix: string, user: UserTokenInfo | null) {
  if (!user) {
    return [];
  }

  return user.permissions
    .filter((p) => p.startsWith(prefix))
    .map((p) => p.substring(prefix.length));
}

export function RequirePermissions(params: RequirePermissionsParams) {
  const { user } = useContext(UserContext);

  const redirect = encodeURIComponent(window.location.pathname + window.location.search);

  return user ? (
    params.permissions.every((p) => hasPermission(user, p)) ? (
      <>{params.children}</>
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <Navigate to={`/login?redirect=${redirect}`} replace />
  );
}

export function RequireSomePermissionPrefix(params: RequirePermissionsParams) {
  const { user } = useContext(UserContext);

  const redirect = encodeURIComponent(window.location.pathname + window.location.search);

  return user ? (
    params.permissions.some((p) => hasPermissionPrefix(user, p)) ? (
      <>{params.children}</>
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <Navigate to={`/login?redirect=${redirect}`} replace />
  );
}
