import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Spinner,
  SpaceBetween,
  Alert,
  Button,
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../../common/navigation';
import { UserListResponse } from '../../../common/types';

import { UserDetails } from './details';
import { UserRolesInfo } from './roles';
import { getUser } from '../../../common/api/users';
import { useQuery } from '@tanstack/react-query';

export default function UserPage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const params = useParams();
  // user UUID
  const userId = params.userId ?? '';
  const [user, setUser] = useState<UserListResponse>();

  const userQuery = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUser(userId),
  });

  const loading = userQuery.isPending;
  const error = userQuery.error;

  useEffect(() => {
    if (userQuery.data) {
      setUser(userQuery.data);
    }
  }, [userQuery.data]);

  const fetchUser = async () => {
    await userQuery.refetch();
  };

  useEffect(() => {
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Users', href: '/manage/users' },
            {
              text: userId,
              href: '',
            },
          ]}
        />
      }
      className="app-layout"
      content={
        user ? (
          <ContentLayout header={<Header variant="h1">{`${user.email}`}</Header>}>
            <Grid
              gridDefinition={[
                { colspan: { default: 12, s: 5 } },
                { colspan: { default: 12, s: 7 } },
              ]}
            >
              <SpaceBetween size="l">
                <UserDetails setUser={setUser} user={user} />
                {/* Allow adding roles like admin */}
                <UserRolesInfo setUser={setUser} user={user} />
              </SpaceBetween>
            </Grid>
          </ContentLayout>
        ) : loading ? (
          <ContentLayout header={<Header variant="h2">Loading</Header>}>
            <Grid gridDefinition={[{ colspan: { s: 10, default: 12 } }]}>
              <Container>
                <Spinner size="big" />
              </Container>
            </Grid>
          </ContentLayout>
        ) : (
          <ContentLayout header={<Header variant="h2">Error</Header>}>
            <Grid gridDefinition={[{ colspan: { s: 10, default: 12 } }]}>
              <Alert
                action={
                  <Button
                    loading={loading}
                    variant="normal"
                    onClick={() => {
                      fetchUser();
                    }}
                  >
                    Retry
                  </Button>
                }
                header="User Error"
                statusIconAriaLabel="Error"
                type="error"
              >
                {error?.message}
              </Alert>
            </Grid>
          </ContentLayout>
        )
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
