import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AppLayout, Box, ContentLayout, Header, Spinner } from '@cloudscape-design/components';

import UserContext from '../common/user';
import { ViewLayout } from '../components/ViewLayout';

export default function Logout() {
  const navigate = useNavigate();
  const { setUser, setToken, setRefreshToken } = useContext(UserContext);

  useEffect(() => {
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh_token');
    setToken(null);
    setRefreshToken(null);
    setUser(null);
    navigate('/');
  }, []);

  return (
    <ViewLayout
      breadcrumbs={[{ text: 'Logout', href: '' }]}
      header={<Header variant="h1">Logging out...</Header>}
    >
      <Box padding="l" textAlign="center">
        <Spinner size="large" />
      </Box>
    </ViewLayout>
  );
}
