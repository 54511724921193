import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import {
  AuthPermission,
  AuthRole,
  Device,
  DeviceWithVenue,
  Order,
  OrderMeta,
  UserLogin,
  UserPasswordReset,
  UserPasswordResetChange,
  UserResponse,
  UserSignup,
} from '../types';

import axiosInstance from './axiosInstance';

export async function loginRequest(info: UserLogin) {
  const response = await axiosInstance.post('/auth/login', info);

  return response.data as UserResponse;
}

export async function accessRequest(code: string) {
  const response = await axiosInstance.post(`/auth/access/${code}`);

  return response.data as UserResponse;
}

export async function resetPassword(info: UserPasswordReset) {
  await axiosInstance.post('/auth/reset', info);
}

export async function changePassword(info: UserPasswordResetChange) {
  await axiosInstance.post('/auth/reset/change', info);
}

export async function getPermissions() {
  const response = await axiosInstance.get('/auth/permissions');

  return response.data as AuthPermission[];
}

export async function getRoles() {
  const response = await axiosInstance.get('/auth/roles');

  return response.data as AuthRole[];
}

export async function getRolePermissions(role: string) {
  const response = await axiosInstance.get(`/auth/roles/${role}/permissions`);

  return response.data as AuthPermission[];
}

export async function userSignupConfirmation(info: UserSignup) {
  const response = await axiosInstance.post('/auth/signup', info);

  return response.data as UserResponse;
}
