import { useCollection } from '@cloudscape-design/collection-hooks';
import { Box, Header, SpaceBetween, Button, Table, Link } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { AuthRole } from '../../common/types';
import { getHeaderCounterText } from '../../common/full-header';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from '../../common/api/auth';

export function RolesTable() {
  const navigate = useNavigate();

  const rolesReq = useQuery({
    queryKey: ['auth', 'roles'],
    queryFn: () => getRoles(),
    staleTime: 360_000,
  });

  const loading = rolesReq.isPending;
  const error = rolesReq.error;

  const fetchRoles = async () => {
    await rolesReq.refetch();
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const { items, collectionProps } = useCollection(rolesReq.data ?? [], {});

  return (
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Name',
          cell: (item) => (
            <Link
              href={`/manage/auth/roles/${item.name}`}
              variant="secondary"
              onFollow={(e) => {
                e.preventDefault();
                navigate(`/manage/auth/roles/${item.name}`);
              }}
            >
              {item.name}
            </Link>
          ),
        },
      ]}
      empty={
        <Box color="inherit" textAlign="center">
          <b>No roles</b>
          <Box color="inherit" padding={{ bottom: 's' }} variant="p">
            No roles to display.
          </Box>
        </Box>
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={loading} iconName="refresh" onClick={fetchRoles} />
            </SpaceBetween>
          }
          counter={getHeaderCounterText(rolesReq.data ?? [], collectionProps.selectedItems)}
        >
          Roles
        </Header>
      }
      items={items}
      loading={loading}
      loadingText="Loading roles"
      trackBy="name"
    />
  );
}
