import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Container,
  Box,
  ColumnLayout,
  Spinner,
  StatusIndicator,
  SpaceBetween,
  DateRangePickerProps,
  Button,
} from '@cloudscape-design/components';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { Venue, VenueWithDevices } from '../../common/types';
import {
  getVenue,
  getVenueOrders,
  getVenueOrdersMeta,
  getVenueDevices,
  getVenueDevicesMeta,
} from '../../common/api/venues';
import { OrderTable } from '../../components/OrderTable';
import {
  useLocalFilterState,
  useLocalPageState,
  useLocalRangeState,
  useLocalSortState,
} from '../../common/filtering/filter';
import { ViewLayout } from '../../components/ViewLayout';
import { LoadingOrError } from '../../components/Loading';
import { DeviceTable } from '../../components/DeviceTable';
import { ZappyHeader } from '../../common/header';
import { PropertyDateFilter } from '../../common/filtering/filtering';
import { getOrderReport, getOrdersMeta } from '../../common/api/orders';
import { OrdersReport } from '../../components/OrdersReport';

export type VenueDetailsProps = {
  venue: VenueWithDevices;
};

export function VenueDetails(props: VenueDetailsProps) {
  const { venue } = props;

  let logo = venue.logo;

  if (logo === null || logo === undefined || logo.trim() === '') {
    logo = 'https://cdn.aiescape.io/zappy-banner.jpg';
  }

  const logoImage = <img alt={`Logo for ${venue.name}`} src={logo} style={{ width: '100%' }} />;

  return (
    <Container
      header={<Header>{venue.name}</Header>}
      media={{
        content: logoImage,
        position: 'top',
        height: '50%',
      }}
      fitHeight
    >
      <SpaceBetween direction="vertical" size="s">
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Name</Box>
            <Box variant="p">{venue.name}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Deal</Box>
            <Box variant="p">{venue.site?.deal?.deal_name ?? '-'}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Type</Box>
            <Box variant="p">{venue.site?.deal?.industry_type ?? 'Uncategorized'}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Address</Box>
            <Box variant="p">{venue.address}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Hours</Box>
            <Box variant="p">
              {venue.open_time && venue.open_time.trim().length > 0 ? venue.open_time : 'Unknown'}
            </Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Currency</Box>
            <Box variant="p">{venue.currency}</Box>
          </div>
        </ColumnLayout>
        {venue.description && venue.description.trim().length > 0 ? (
          <ColumnLayout columns={1} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <Box variant="p">{venue.description}</Box>
            </div>
          </ColumnLayout>
        ) : null}
      </SpaceBetween>
    </Container>
  );
}

function Content(props: { item: VenueWithDevices }) {
  const { item } = props;

  const past = true;
  const [now, setNow] = useState(new Date());
  const orderQuery = useLocalFilterState();
  const orderRange = useLocalRangeState(past, 'last-1-month');
  const orderPage = useLocalPageState();
  const orderSort = useLocalSortState('rent_time', 'desc');

  const deviceQuery = useLocalFilterState();
  const devicePage = useLocalPageState();
  const deviceSort = useLocalSortState('device_id', 'desc');

  const metaQuery = useQuery({
    queryKey: ['orders', 'meta', now],
    queryFn: getOrdersMeta,
    staleTime: 3_600_000, // 1 hour
  });

  const { options, properties } = useMemo(() => {
    if (metaQuery.data) {
      return {
        options: metaQuery.data.filteringOptions,
        properties: metaQuery.data.filteringProperties,
      };
    }

    return {
      options: [],
      properties: [],
    };
  }, [metaQuery.data]);

  const refresh = () => {
    setNow(new Date());
  };

  // wait for the meta query to finish before proceeding
  const reportQuery = useQuery({
    queryKey: ['orders', orderQuery.query, orderRange.range, now],
    enabled: metaQuery.isSuccess,
    queryFn: async () => {
      const orders = await getOrderReport(
        {
          operation: orderQuery.query.operation,
          tokens: [
            ...orderQuery.query.tokens,
            {
              value: item.name,
              propertyKey: 'venue_name',
              operator: '=',
            },
          ],
        },
        orderRange.range,
        now,
      );

      return orders;
    },
    staleTime: 300_000, // 5 minutes
  });

  const loading =
    (reportQuery.isPending ||
      reportQuery.isFetching ||
      metaQuery.isPending ||
      metaQuery.isFetching) &&
    !reportQuery.error &&
    !metaQuery.error;

  const error = reportQuery.error || metaQuery.error;

  const itemFilter = (
    <PropertyDateFilter
      filteringProperties={properties}
      loading={loading}
      past={past}
      propertyFilteringOptions={options}
      query={orderQuery.query}
      range={orderRange.range ?? null}
      setQuery={(q) => {
        orderQuery.setQuery?.(q);
      }}
      setRange={(r) => {
        orderRange.setRange?.(r);
      }}
    />
  );

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, s: 6 } },
        { colspan: { default: 12, s: 6 } },
        { colspan: { default: 12, s: 12 } },
        { colspan: { default: 12, s: 12 } },
      ]}
    >
      <VenueDetails venue={item} />
      <SpaceBetween direction="vertical" size="s">
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Button
                    disabled={loading}
                    iconName="refresh"
                    variant="normal"
                    onClick={refresh}
                  />
                </SpaceBetween>
              }
            >
              Report Settings
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            {itemFilter}
            {reportQuery.data && (
              <OrdersReport
                description={`Overview for ${item.name} within the specified time range`}
                report={reportQuery.data}
                title={`${item.name} Overview`}
              />
            )}
          </SpaceBetween>
        </Container>
        {(loading || error) && <LoadingOrError error={error} loading={loading} retry={refresh} />}
      </SpaceBetween>
      <OrderTable
        {...orderQuery}
        {...orderRange}
        {...orderPage}
        {...orderSort}
        getItems={(query, page, pageSize, range, sortingField, sortDirection, now) =>
          getVenueOrders(item.uuid, query, page, pageSize, range, sortingField, sortDirection, now)
        }
        getMeta={() => getVenueOrdersMeta(item.uuid)}
        hiddenColumns={['venue_name']}
        hideFilter
        now={now}
        setNow={setNow}
        variant="container"
      />
      <DeviceTable
        {...deviceQuery}
        {...devicePage}
        {...deviceSort}
        getItems={(query, page, pageSize, range, sortingField, sortDirection, now) =>
          getVenueDevices(item.uuid, query, page, pageSize, range, sortingField, sortDirection, now)
        }
        getMeta={() => getVenueDevicesMeta(item.uuid)}
        hiddenColumns={['venue_name']}
        now={now}
        setNow={setNow}
        variant="container"
      />
    </Grid>
  );
}

export default function VenuePage() {
  const params = useParams();
  // Venue UUID
  const venueId = params.venueId ?? '';

  const { data, isPending, isFetching, error, refetch } = useQuery({
    queryKey: ['venue', venueId],
    queryFn: () => getVenue(venueId),
    staleTime: 86_400_000, // 1 day
  });

  const loading = (isPending || isFetching) && !error;

  return (
    <ViewLayout
      breadcrumbs={[
        { text: 'Venues', href: '/venues' },
        {
          text: data ? data.name : loading ? '' : '',
          href: '',
        },
      ]}
      contentType="dashboard"
      header={<ZappyHeader title={data ? `@ ${data.name}` : ' '} />}
      headerVariant="high-contrast"
    >
      {loading || error || !data ? (
        <LoadingOrError
          error={error}
          loading={loading}
          retry={() => {
            refetch();
          }}
        />
      ) : (
        <Content item={data} />
      )}
    </ViewLayout>
  );
}
