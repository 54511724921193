import { RoleUpdateRequest, UserListResponse, UserUpdate } from '../types';
import axiosInstance from './axiosInstance';

export async function updateUserRoles(uuid: string, data: RoleUpdateRequest) {
  await axiosInstance.post(`/users/${uuid}/roles`, data);
}

export async function getUser(uuid: string) {
  const response = await axiosInstance.get(`/users/${uuid}`);

  return response.data as UserListResponse;
}

export async function getUsers() {
  const response = await axiosInstance.get(`/users`);

  return response.data as UserListResponse[];
}
