import {
  Container,
  Box,
  Grid,
  SpaceBetween,
  Button,
  GridProps,
  Modal,
  Link,
  Header,
  BarChart,
  Spinner,
  StatusIndicator,
} from '@cloudscape-design/components';

import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  ChatMessage,
  GraphMessage,
  LoadingMessage,
  PieChartMessage,
  TableMessage,
  TextMessage,
  ValueMessage,
} from '../common/chat/types';
import { CenteredComparison, CenteredTextComparison } from '../dashboard/reporting';
import { formatFinancial } from '../common/formatting';

import { ChatGraph } from './graph';
import { ChatTable } from './table';
import { ChatPieChart } from './pie';

export type ChatTurnProps = {
  index: number;
  message: ChatMessage;
  addMessage?: (message: string) => void;
  loading?: boolean;
};

export function ChatTurn(props: ChatTurnProps) {
  const message = props.message;
  const index = props.index;

  const author = (
    <Box variant="small">
      {message.role === 'user' ? 'User' : 'AI'}
      {message.created_at ? ` | ${new Date(message.created_at + 'Z').toLocaleString()}` : ''}
    </Box>
  );

  let content = null;

  if (message.type === 'text') {
    const textMessage = message as TextMessage;
    const messageText = textMessage.message;

    const text = messageText
      .trim()
      .split('\n')
      .filter((line) => line.trim().length > 0)
      .map((line, line_idx) => (
        <Box key={`turn-${index}-line-${line_idx}`} className="cut-text" variant="p">
          {line.trim()}
        </Box>
      ));

    if (textMessage.options && props.addMessage) {
      const options = textMessage.options.map((option, option_idx) => (
        <Button
          key={`turn-${index}-option-${option_idx}`}
          className="chat-option-button"
          disabled={props.loading}
          variant="normal" //'normal' | 'primary' | 'link' | 'icon' | 'inline-icon' | 'inline-link'
          fullWidth
          onClick={() => {
            if (props.addMessage && !props.loading) {
              props.addMessage(option.value);
            }
          }}
        >
          <Header description={option.subtitle} variant="h3">
            {option.title}
          </Header>
        </Button>
      ));
      let gridDefinition: ReadonlyArray<GridProps.ElementDefinition> = [];

      if (options.length === 1) {
        gridDefinition = [{ colspan: { default: 12, xs: 6 } }];
      } else if (options.length === 2) {
        gridDefinition = [{ colspan: { default: 12, xs: 6 } }, { colspan: { default: 12, xs: 6 } }];
      } else if (options.length === 3) {
        gridDefinition = [
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } },
        ];
      } else if (options.length === 4) {
        gridDefinition = [
          { colspan: { default: 12, xs: 6 } },
          { colspan: { default: 12, xs: 6 } },
          { colspan: { default: 12, xs: 6 } },
          { colspan: { default: 12, xs: 6 } },
        ];
      } else {
        gridDefinition = options.map(() => ({ colspan: { default: 12, xs: 6 } }));
      }

      content = (
        <SpaceBetween direction="vertical" size="xs">
          {text}
          <Grid gridDefinition={gridDefinition}>{options}</Grid>
        </SpaceBetween>
      );
    } else {
      content = (
        <SpaceBetween direction="vertical" size="xs">
          {text}
        </SpaceBetween>
      );
    }
  } else if (message.type === 'graph') {
    const graphMessage = message as GraphMessage;
    // console.log(graphMessage);

    content = <ChatGraph message={graphMessage} />;
  } else if (message.type === 'table') {
    const tableMessage = message as TableMessage;
    // console.log(tableMessage);

    content = <ChatTable message={tableMessage} />;
  } else if (message.type === 'value') {
    const valueMessage = message as ValueMessage;

    if (valueMessage.value_type === 'currency' || valueMessage.value_type === 'number') {
      content = (
        <CenteredComparison
          description={valueMessage.subtitle}
          formatter={
            valueMessage.value_type === 'number'
              ? (x) =>
                  x.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
              : undefined
          }
          previousValue={valueMessage.prev_value as number | undefined}
          value={valueMessage.value as number}
          variant="percentage" // TODO let the AI choose if it should be a percentage or absolute difference
          insideContainer
        >
          {valueMessage.title}
        </CenteredComparison>
      );
    } else if (valueMessage.value_type === 'datetime') {
      content = (
        <CenteredTextComparison
          description={valueMessage.subtitle}
          previousValue={
            valueMessage.prev_value
              ? (new Date((valueMessage.prev_value as string) + 'Z').toLocaleString() as
                  | string
                  | undefined)
              : undefined
          }
          value={new Date((valueMessage.value as string) + 'Z').toLocaleString()}
          insideContainer
        >
          {valueMessage.title}
        </CenteredTextComparison>
      );
    } else if (valueMessage.value_type === 'string') {
      content = (
        <CenteredTextComparison
          description={valueMessage.subtitle}
          previousValue={valueMessage.prev_value as string | undefined}
          value={valueMessage.value as string}
          insideContainer
        >
          {valueMessage.title}
        </CenteredTextComparison>
      );
    }
  } else if (message.type === 'loading') {
    const loadingMessage = message as LoadingMessage;

    if (loadingMessage.error) {
      let text = '';

      if (loadingMessage.name === 'show_graph') {
        text = 'Graph error';
      } else if (loadingMessage.name === 'show_table') {
        text = 'Table error';
      } else if (loadingMessage.name === 'run_sql') {
        text = 'Data error';
      } else if (loadingMessage.name === 'show_value') {
        text = 'Value error';
      }
      content = (
        <Box variant="p">
          <StatusIndicator type="error">{loadingMessage.name ? text : ''}</StatusIndicator>
        </Box>
      );
    } else if (loadingMessage.complete) {
      let text = '';

      if (loadingMessage.name === 'show_graph') {
        text = 'Graph built';
      } else if (loadingMessage.name === 'show_table') {
        text = 'Table built';
      } else if (loadingMessage.name === 'run_sql') {
        text = 'Data loaded';
      } else if (loadingMessage.name === 'show_value') {
        text = 'Value loaded';
      }
      content = (
        <Box variant="p">
          <StatusIndicator type="success">{loadingMessage.name ? text : ''}</StatusIndicator>
        </Box>
      );
    } else {
      let text = '';

      if (loadingMessage.name === 'show_graph') {
        text = 'Building graph...';
      } else if (loadingMessage.name === 'show_table') {
        text = 'Building table...';
      } else if (loadingMessage.name === 'run_sql') {
        text = 'Loading data...';
      } else if (loadingMessage.name === 'show_value') {
        text = 'Loading value...';
      }

      content = (
        <Box variant="p">
          {/* TODO friendly name and icon here */}
          <Spinner size="normal" /> {loadingMessage.name ? text : ''}
        </Box>
      );
    }
  } else if (message.type === 'pie') {
    const pieMessage = message as PieChartMessage;
    // console.log(pieMessage);
    content = <ChatPieChart message={pieMessage} />;
  } else {
    console.error('Unknown message type: ', message);
  }

  // .escape-chat-box {
  //   /* place at bottom of container */
  //   position: absolute;
  //   width: 100%;
  //   bottom: 0;
  // }

  return (
    <Container
      key={`turn-${index}`}
      className={
        message.role === 'user'
          ? 'chat-turn-user'
          : message.type === 'text' || message.type === 'loading'
          ? 'chat-turn-assistant-text'
          : 'chat-turn-assistant-other'
      }
      disableHeaderPaddings
    >
      {author}
      {content}
    </Container>
  );
}
