import { createContext } from 'react';

import { UserTokenInfo } from './types';

export type UserContextType = {
  user: UserTokenInfo | null;
  setUser: (user: UserTokenInfo | null) => void;
  token: string | null;
  setToken: (token: string | null) => void;
  refreshToken: string | null;
  setRefreshToken: (refreshToken: string | null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: (_user: UserTokenInfo | null) => {
    return;
  },
  token: null,
  setToken: (_token: string | null) => {
    return;
  },
  refreshToken: null,
  setRefreshToken: (_refreshToken: string | null) => {
    return;
  },
  loading: false,
  setLoading: (_loading: boolean) => {
    return;
  },
});

export default UserContext;

export function hasPermission(user: UserTokenInfo | null, permission: string): boolean {
  if (!user) {
    return false;
  }

  return user.permissions.includes(permission);
}

export function hasPermissions(user: UserTokenInfo | null, permissions: string[]): boolean {
  if (!user) {
    return false;
  }

  return permissions.every((permission) => user.permissions.includes(permission));
}

export function hasPermissionPrefix(user: UserTokenInfo | null, prefix: string): boolean {
  if (!user) {
    return false;
  }

  return user.permissions.some((permission) => permission.startsWith(prefix));
}
