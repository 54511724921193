import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { UserInvite, UserInviteResponse } from '../../common/types';
import { getTextFilterCounterText } from '../../common/i18n-strings';
import { getHeaderCounterText } from '../../common/full-header';

import { filterInvites } from './filter';
import { inviteUsers, deleteEmailInvites, getInvites } from '../../common/api/invite';
import { useMutation, useQuery } from '@tanstack/react-query';

export function InviteTable() {
  const pageSize = 10;
  const resourceName = 'Invite';
  const [selectedItems, setSelectedItems] = useState<UserInviteResponse[]>([]);

  const [now, setNow] = useState(new Date());
  const { data, isPending, isFetching, error } = useQuery({
    queryKey: ['invites', now],
    queryFn: () => getInvites(),
    staleTime: 0, // 1 day
  });

  const sendInvite = useMutation({
    mutationFn: (info: UserInvite[]) => inviteUsers(info),
    onSuccess: () => {
      setNow(new Date());
    },
  });
  const sendDelete = useMutation({
    mutationFn: (emails: string[]) => deleteEmailInvites(emails),
    onSuccess: () => {
      setNow(new Date());
    },
  });

  const loading = isFetching || sendInvite.isPending || sendDelete.isPending;

  const fetchInvites = async () => {
    setSelectedItems([]);
    setNow(new Date());
  };

  const deleteInvites = async () => {
    if (loading) {
      return;
    }

    const emails = selectedItems.map((item) => item.email);
    await sendDelete.mutateAsync(emails);
  };

  const resendInvites = async () => {
    if (loading) {
      return;
    }

    const emails = selectedItems.filter((item) => !item.redeemed).map((item) => item.email);

    if (emails.length === 0) {
      return;
    }

    await sendInvite.mutateAsync(emails.map((email) => ({ email })));
  };

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(data ?? [], {
    propertyFiltering: {
      filteringProperties: [
        {
          key: 'email',
          operators: [':', '!:'],
          propertyLabel: 'Email',
          groupValuesLabel: 'Email values',
          defaultOperator: ':',
        },
        {
          key: 'status',
          operators: ['=', '!='],
          propertyLabel: 'Status',
          groupValuesLabel: 'Status values',
        },
        {
          key: 'roles',
          operators: [':', '!:'],
          propertyLabel: 'Roles',
          groupValuesLabel: 'Role values',
          defaultOperator: ':',
        },
      ],
      filteringFunction: filterInvites,
      defaultQuery: {
        operation: 'and',
        tokens: [
          {
            propertyKey: 'status',
            operator: '=',
            value: 'Redeemed',
          },
        ],
      },
      empty: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error.message}
                </Box>
              </div>
              <Button onClick={fetchInvites}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      ),
      noMatch: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No matches</b>
              <Box color="inherit" variant="p">
                We can&apos;t find a match.
              </Box>
            </div>
            <Button onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}>
              Clear filter
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize },
    selection: {},
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'sent',
          sortingComparator(a, b) {
            const x = new Date(a.sent_at);
            const y = new Date(b.sent_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...propertyFilterProps}
      {...paginationProps}
      ariaLabels={{
        selectionGroupLabel: 'Items selection',
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
        itemSelectionLabel: ({ selectedItems }, item) => {
          const isItemSelected = selectedItems.filter((i) => i.email === item.email).length;

          return `${item.email} is ${isItemSelected ? '' : 'not'} selected`;
        },
      }}
      columnDefinitions={[
        {
          id: 'email',
          header: 'Email',
          cell: (e) => e.email,
          sortingField: 'email',
          sortingComparator(a, b) {
            const x = a.email.toLowerCase();
            const y = b.email.toLowerCase();

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'status',
          header: 'Status',
          cell: (e) => (
            <StatusIndicator type={e.redeemed ? 'success' : 'in-progress'}>
              {e.redeemed ? 'Redeemed' : 'Pending'}
            </StatusIndicator>
          ),
          sortingField: 'status',
          sortingComparator(a, b) {
            const x = a.redeemed ? 1 : 0;
            const y = b.redeemed ? 1 : 0;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        // {
        //   id: 'roles',
        //   header: 'Roles',
        //   cell: (e) => e.roles.map((x) => x[0].toUpperCase() + x.slice(1)).join(', '),
        //   sortingField: 'roles',
        //   sortingComparator(a, b) {
        //     const x = a.roles
        //       .map((x) => x[0].toUpperCase() + x.slice(1))
        //       .join(', ')
        //       .toLowerCase();
        //     const y = b.roles
        //       .map((x) => x[0].toUpperCase() + x.slice(1))
        //       .join(', ')
        //       .toLowerCase();

        //     return x < y ? -1 : x > y ? 1 : 0;
        //   },
        // },
        {
          id: 'sent',
          header: 'Sent',
          cell: (e) => new Date(e.sent_at + 'Z').toLocaleString(),
          sortingField: 'sent',
          sortingComparator(a, b) {
            const x = new Date(a.sent_at);
            const y = new Date(b.sent_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'redeemed',
          header: 'Redeemed',
          cell: (e) => (e.redeemed_at ? new Date(e.redeemed_at + 'Z').toLocaleString() : ''),
          sortingField: 'redeemed',
          sortingComparator(a, b) {
            const x = a.redeemed_at ? new Date(a.redeemed_at) : 0;
            const y = b.redeemed_at ? new Date(b.redeemed_at) : 0;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ]}
      empty={
        <Box color="inherit" textAlign="center">
          <b>No invites</b>
          <Box color="inherit" padding={{ bottom: 's' }} variant="p">
            No invites to display.
          </Box>
        </Box>
      }
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount || 0)}
          disabled={loading}
          filteringOptions={[
            {
              propertyKey: 'status',
              value: 'Redeemed',
            },
            {
              propertyKey: 'status',
              value: 'Pending',
            },
            {
              propertyKey: 'roles',
              value: 'Basic',
            },
            {
              propertyKey: 'roles',
              value: 'Advanced',
            },
            {
              propertyKey: 'roles',
              value: 'Admin',
            },
          ]}
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={loading} iconName="refresh" onClick={fetchInvites} />
              <Button disabled={loading || selectedItems.length === 0} onClick={resendInvites}>
                Resend
              </Button>
              <Button disabled={loading || selectedItems.length === 0} onClick={deleteInvites}>
                Delete
              </Button>
            </SpaceBetween>
          }
          counter={getHeaderCounterText(data ?? [], collectionProps.selectedItems)}
        >
          User Invites
        </Header>
      }
      items={items}
      loading={loading}
      loadingText="Loading invites"
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      selectedItems={selectedItems}
      selectionType="multi"
      trackBy="email"
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
    />
  );
}
